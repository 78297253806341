import React from 'react';

import ButtonStyled from './button.styled';

const Button = (props: { className?: string; label: string; url: string }) => {
  return (
    <ButtonStyled className={props.className} to={props.url}>
      {props.label}
    </ButtonStyled>
  );
};

export default Button;
