import { Link, withPrefix } from 'gatsby';
import styled, { css } from 'styled-components';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const PaintingStyled = styled(Link)`
  ${() => {
    const props = {};

    validateProps('PaintingStyled', { ...props });

    return css`
      display: block;
      padding: 1.6rem 1.6rem 3.2rem;
      background: #fff;
      text-decoration: none;
      box-shadow: 0 0.4rem 4rem rgb(0 0 0 / 20%);
      border-radius: 0.8rem;
      text-align: right;
      color: #222;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .image-wrap {
        position: relative;
        font-size: 0;
        line-height: 0;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.15);
          opacity: 0;
          z-index: 2;
          transition: all 0.5s ease-in-out;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 40px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.5);
          background-image: url(${withPrefix('/images/visibility.svg')});
          background-size: 32px 32px;
          background-position: center center;
          background-repeat: no-repeat;
          opacity: 0;
          z-index: 3;
          transition: all 0.5s ease-in-out;
          transform: translate(-50%, -50%) scale(0.9);
          border-radius: 50%;
        }
      }

      img {
        transition: all 0.5s ease-in-out;
        transform: scale(1);
      }

      &:hover {
        box-shadow: 0 0.4rem 4rem rgb(0 0 0 / 35%);

        .image-wrap {
          &::before {
            opacity: 1;
          }
          &::after {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
          }
        }
        // img {
        //   transform: scale(1.025);
        // }
      }

      .title {
        font-weight: bold;
        margin-top: 0.8rem;
      }
    `;
  }}
`;

export default PaintingStyled;
