import React from 'react';

import { ContentTypes } from '~/schema/types';
import SchemaOrg from '../schemaOrg';
import { SchemaOrgTypes } from '../schemaOrg/schemaOrg.types';

import ImageStyled from './image.styled';

const Image = (props: ContentTypes.Image) => {
  const ImageSchema: SchemaOrgTypes.Schema_Image = {
    '@type': 'ImageObject',
    contentUrl: props.url_webp,
    description: props.altText,
    name: props.title || props.altText,
  };

  if (props.location) ImageSchema.contentLocation = props.location;

  return (
    <>
      <SchemaOrg schema={ImageSchema} />
      <ImageStyled>
        <source type="image/webp" srcSet={props.url_webp} />
        <source type="image/jpeg" srcSet={props.url_jpg} />
        <img
          src={props.url_jpg}
          alt={props.altText}
          width={props.width}
          height={props.height}
        />
      </ImageStyled>
    </>
  );
};

export default Image;
