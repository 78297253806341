import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const ButtonStyled = styled(Link)<{
  to: string;
}>`
  ${({ theme }) => {
    const props = {
      backgroundColor: theme?.button?.backgroundColor,
      textColor: theme?.button?.textColor,
    };

    validateProps('ButtonStyled', { ...props });

    return css`
      position: relative;
      display: inline-block;
      padding: 0.8rem 4rem 0.8rem 2.4rem;
      background: ${props.backgroundColor};
      color: ${props.textColor};
      text-transform: uppercase;
      text-decoration: none;
      opacity: 1;
      transition: opacity 400ms ease-out;
      border-radius: 2rem;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);

      &::after {
        content: '';
        position: absolute;
        transform: rotate(45deg) translateY(-50%);
        border: 0.2rem solid;
        border-left: none;
        border-bottom: none;
        width: 0.8rem;
        height: 0.8rem;
        top: 50%;
        right: 2.4rem;
        transition: right 400ms;
      }

      &:hover {
        opacity: 0.8;
        color: ${props.textColor};

        &::after {
          right: 1.6rem;
        }
      }
    `;
  }}
`;

export default ButtonStyled;
