import React from 'react';

import { ContentTypes } from '~/schema/types';

import PaintingStyled from './painting.styled';
import Image from '../image';
import SchemaOrg from '../schemaOrg';
import { SchemaOrgTypes } from '../schemaOrg/schemaOrg.types';

const Painting = (props: {
  image: ContentTypes.Image;
  location: string;
  medium: string;
  title: string;
  url: string;
}) => {
  if (!props.image) return null;

  const PaintingSchema: SchemaOrgTypes.Schema_Painting = {
    '@type': 'Painting',
    name: props.title,
    image: props.image.url_webp,
    artMedium: props.medium,
    contentLocation: props.location,
  };

  return (
    <>
      <SchemaOrg schema={PaintingSchema} />
      <PaintingStyled to={props.url}>
        <div className="image-wrap">
          <Image {...props.image} />
        </div>
        <div className="title">{props.title}</div>
      </PaintingStyled>
    </>
  );
};

export default Painting;
