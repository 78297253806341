import React from 'react';

import Intro from '~/components/intro';
import { Spacer } from '~/components/layout';

import Latest from '~/components/latest';
import { ContentTypes } from '~/schema/types';

const Home = (props: { pageContext: ContentTypes.Home }) => {
  const { content, image, featured, title } = props.pageContext;

  return (
    <>
      <Spacer size="large">
        <Intro content={content} image={image} title={title} />
      </Spacer>
      {featured && featured.length > 0 && (
        <Spacer size="large">
          <Latest paintings={featured} />
        </Spacer>
      )}
    </>
  );
};

export default Home;
