import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const LatestStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      titleStyles: theme?.typeStyles?.h2,
    };

    validateProps('LatestStyled', { ...props });

    return css`
      background-color: #333;
      ${spacer('large', 'padding-top-bottom')}

      .latest-title {
        ${props.titleStyles}
        color: #fff;
        text-align: center;
      }

      .cta {
        ${spacer('medium')}
        text-align: center;
      }
    `;
  }}
`;

export default LatestStyled;
