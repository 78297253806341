import React from 'react';

import Button from '../button';
import { Container } from '~/components/layout';

import IntroStyled from './intro.styled';
import { ContentTypes } from '~/schema/types';
import Markup from '../markup';
import { content_page_schema } from '~/schema/site.schema';
import Image from '../image';

const Intro = (props: {
  content: string;
  image: ContentTypes.Image;
  title: string;
}) => {
  return (
    <Container>
      <IntroStyled>
        <div className="inner">
          <div className="image">
            <div className="image__inner">
              <Image {...props.image} />
            </div>
          </div>
          <div className="content">
            <div className="content__inner">
              <h2 className="title">{props.title}</h2>
              <Markup className="markup" html={props.content} />
              <Button
                className="cta"
                url={content_page_schema.paintings.path}
                label={`Go to paintings & prints gallery`}
              />
            </div>
          </div>
        </div>
      </IntroStyled>
    </Container>
  );
};

export default Intro;
