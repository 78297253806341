import styled, { css } from 'styled-components';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const ImageStyled = styled.picture<{
  theme?: object;
}>`
  ${() => {
    const props = {};

    validateProps('ImageStyled', { ...props });

    return css``;
  }}
`;

export default ImageStyled;
