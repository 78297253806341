import styled, { css } from 'styled-components';
import validateProps from '~/utils/validateProps';
import { mq } from '~/theme/patterns';

const IntroStyled = styled.div`
  ${({ theme }) => {
    const props = {
      titleStyles: theme?.typeStyles?.h2,
    };

    validateProps('IntroStyled', { ...props });

    return css`
      max-width: 60rem;
      margin: auto;
      @media ${mq.medium} {
        max-width: 100%;
      }

      .image {
        img {
          display: block;
          width: 100%;
        }

        &__inner {
          width: 100%;
        }
      }

      .content {
        position: relative;
        z-index: 2;
        margin-top: -6.4rem;
        padding: 0 1.6rem;

        @media ${mq.xsmall} {
          margin-top: -12rem;
        }

        @media ${mq.smedium} {
          margin-top: -16rem;
        }

        &__inner {
          width: 100%;
          padding: 2.4rem 1.6rem;
          background-color: #fff;
          border-radius: 0.8rem;
          box-shadow: 0 0.4rem 4rem rgb(0 0 0 / 20%);

          @media ${mq.smedium} {
            padding: 3.2rem;
          }

          @media ${mq.medium} {
            padding: 6.4rem;
          }

          @media ${mq.large} {
            padding: 6.4rem 10.4rem;
          }
        }
      }

      .title {
        ${props.titleStyles}
      }

      .markup {
        margin-top: 2.4rem;
      }

      .cta {
        display: inline-block;
        margin-top: 3.2rem;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
      }

      @media ${mq.medium} {
        .inner {
          margin-left: -${100 / 12}%;
          margin-right: -${100 / 12}%;
          display: flex;
        }

        .image {
          flex-basis: 50%;
          margin-top: 5.6rem;

          &__inner {
            margin-left: ${100 / 7}%;
          }
        }

        .content {
          margin-top: 0;
          flex-basis: 50%;
          padding: 0;

          &__inner {
            margin-left: -${100 / 7}%;
          }
        }
      }
    `;
  }}
`;

export default IntroStyled;
