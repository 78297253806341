import React from 'react';

import Button from '../button';
import { Container, FlexGrid, Spacer } from '../layout';
import Painting from '../painting';

import LatestStyled from './latest.styled';

import { content_page_schema } from '~/schema/site.schema';
import { ContentTypes } from '~/schema/types';

const Latest = (props: { paintings: ContentTypes.Painting[] }) => {
  return (
    <LatestStyled>
      <Container>
        <h2 className="latest-title">Latest Paintings</h2>
        <Spacer size="medium">
          <FlexGrid
            columns={{ xsmall: 2, mlarge: 4 }}
            gutter={{ base: '0.8rem', xsmall: '1.6rem' }}
          >
            {props.paintings &&
              props.paintings.map((painting: ContentTypes.Painting) => (
                <Painting
                  key={painting.id}
                  image={painting.image}
                  location={painting.location}
                  medium={painting.medium}
                  title={painting.title}
                  url={`paintings/${painting.url}`}
                />
              ))}
          </FlexGrid>
        </Spacer>
        <div className="cta">
          <Button
            label="View all paintings and prints"
            url={content_page_schema.paintings.path}
          />
        </div>
      </Container>
    </LatestStyled>
  );
};

export default Latest;
